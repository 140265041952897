import React from 'react';
import { graphql, Link } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import { GatsbyImage } from 'gatsby-plugin-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Helmet from 'react-helmet';
import NearbyNow from '../components/NearbyNow';

const Areas = ({ data }) => {
  const { seoTitle } = data.mdx.frontmatter;
  const { title } = data.mdx.frontmatter;
  const { seoDesc } = data.mdx.frontmatter;
  const { body } = data.mdx.body;
  const financingAd = data.financingAd;
  return (
    <Layout bodyClass="page-service ">
      <SEO title={seoTitle} description={seoDesc} />
      <div className="content container2">
        <div className="container22">
          <div className="content service service-single">
            <MDXRenderer>{data.mdx.body}</MDXRenderer>
            <div className="banner-image financing-banner">
              <Link to="/financing">
                <GatsbyImage
                  alt="Best MN HVAC Apple Valley, Eagan, Savage, mn"
                  image={financingAd.childImageSharp.gatsbyImageData}
                />
              </Link>
            </div>
            <NearbyNow />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        seoTitle
        seoDesc
        path
      }
      body
    }
    financingAd: file(relativePath: { eq: "furnace-repair-ac-service-2.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;

export default Areas;
