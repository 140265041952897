import React from 'react'
import Helmet from 'react-helmet'

const NearbyNow = () => {

    const api = 'https://api.sidebox.com/plugin/nearbyserviceareareviewcombo?storefronttoken=9fc46745-d8c5-4235-89c6-45e22e86a9b5&state={1}&city={2}&zoomlevel={3}&radius={4}&showmap={5}&techemail={6}&mapscrollwheel={7}&fblike={8}&fbcomment={9}&agent={10}'
    return(
        <>
        <Helmet>
            <meta name="NearbyNow Testing" content='Testing NearbyNow'/>
            <script src="//ajax.googleapis.com/ajax/libs/jquery/1.10.2/jquery.min.js" />
        <link href="https://d2gwjd5chbpgug.cloudfront.net/v3/css/nnplugin.min.css" rel="stylesheet" type="text/css" />
        <script src="https://d2gwjd5chbpgug.cloudfront.net/v3/scripts/heatmap.min.js" />
        </Helmet>
        </>
    )
}

export default NearbyNow